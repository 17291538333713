<template>
  <el-button type="primary" @click="clear" style="margin: 30px">清除数据并退出登录</el-button>
</template>

<script>
  export default {
    name: "index",
    methods: {
      clear() {
        console.log('clear')
      }
    }
  }
</script>

<style scoped>

</style>